import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import { useRegistration } from '../context/RegistrationContext';
import { functions } from '../firebase';

/**
 * FitbitCallback component handles the callback from Fitbit's OAuth process.
 * It retrieves the authorization code and state from the URL, fetches the participant data,
 * and allows the user to agree to terms and proceed.
 */
function FitbitCallback() {
  const {
    pid,
    participantId,
    projectId,
    participant,
    setParticipant,
    activeStep,
    loading,
    setLoading,
    error,
    setError,
  } = useRegistration();

  const [code, setCode] = useState(null);
  const [state, setState] = useState(null);
  const location = useLocation();

  var cookie_pid = document.cookie.replace(/(?:(?:^|.*;\s*)pid\s*=\s*([^;]*).*$)|^.*$/, "$1");
  var cookie_participantId = document.cookie.replace(/(?:(?:^|.*;\s*)participantId\s*=\s*([^;]*).*$)|^.*$/, "$1");

  // State variables
  const [agreeTerms, setAgreeTerms] = useState(false);

  const navigate = useNavigate();
  const { addAlert } = useAlert();
  // Extract 'code' and 'state' from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeParam = searchParams.get('code');
    const stateParam = searchParams.get('state');
    
    setCode(codeParam);
    setState(stateParam);

    if (!codeParam || !stateParam) {
      console.log("Missing code or state in URL");
      addAlert('error', 'Missing code or state in URL');
    }
  }, [addAlert]);

  // printing, updating, and printing cookie_pid and cookie_participantId on mount
  useEffect(() => {
    console.log("current. pid: %s, participantId: %s", pid, participantId);
    console.log("current, cookie_pid: %s, cookie_participant_Id: %s", cookie_pid, cookie_participantId);
    console.log("updated, cookie_pid: %s, cookie_participant_Id: %s",
      document.cookie.replace(/(?:(?:^|.*;\s*)pid\s*=\s*([^;]*).*$)|^.*$/, "$1"),
      document.cookie.replace(/(?:(?:^|.*;\s*)participantId\s*=\s*([^;]*).*$)|^.*$/, "$1")
    );
  }, []);

  /**
   * Handles the completion of Fitbit authorization and updates the registration step accordingly.
   */
  const handleCompleteFitbitAuth = async () => {
    if (loading) return;

    setLoading(true);
    if (!agreeTerms) {
      addAlert("error", "Please agree to the terms and conditions");
      setLoading(false);
      return;
    }

    try {
      // TODO: Fix this, it's were the error is coming from
      // I think it's because we are not passing the participantId to the function
      // 
      // Call the Firebase function to handle the authorization redirect
      const handleAuthorizationRedirect = httpsCallable(functions, 'handleAuthorizationRedirect');
      const res = await handleAuthorizationRedirect({ code: code, state: state, pid: pid, participantId: participantId });
      console.log("handleAuthorizationRedirect, res", res);
      if (res?.data?.participant?.fitbitData?.access_token) {
        setParticipant(res.data.participant);
        
        // Do whatever youre doing in completeRegistration in functions handleAuthorizationRedirect
        addAlert("success", "Fitbit authorization completed successfully");
      } else {
        throw new Error('Fitbit authorization failed');
      }
    } catch (err) {
      error(`Fitbit authorization failed: ${err?.message}`);
      setError('Fitbit authorization failed.');
      addAlert('error', 'Fitbit authorization failed.');
    } finally {
      setLoading(false);
      navigate(`/reg/prolificRegistration/${participant?.projectId}/pId/${pid}`);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card>
        <CardHeader title="Fitbit Consent Form" />
        <CardContent>
          {(loading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          )}
          <Typography variant="body1">
            Hereby I give my consent to share my Fitbit wearable data with Jamasp.
            I understand that my data will be used for research purposes and will be kept confidential.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            I understand that I can withdraw my consent at any time.
          </Typography>
          <FormControlLabel sx={{ mt: 3 }}
            control={<Checkbox value="accept" required
              onChange={() => setAgreeTerms(!agreeTerms)} color="primary" checked={agreeTerms} />}
            label="By clicking this box, I accept the Terms of Service and Privacy Policy."
          />
        </CardContent>
        <CardActions>
          <Button disabled={!agreeTerms || loading} variant="contained" color="primary" onClick={handleCompleteFitbitAuth}>
            Complete Authorization
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default FitbitCallback;