//create a react component named ShowParticipant that shows basic participant Info

import { Typography } from "@mui/material";

export default function ShowParticipant(props){

  return (
    <div>
      <Typography variant="h4">Participant Information</Typography>
    </div>
  )

}
