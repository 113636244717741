import { Box, Button, CircularProgress, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { useRegistration } from '../../context/RegistrationContext';

const ProlificRegistrationStepper = () => {
  const {
    pid,
    setPid,
    activeStep,
    storePid,
    initiateFitbitAuth,
    error,
    success,
    loading,
    updateRegistrationStep,
    participant,
    participantId,
    projectId,
    addAlert
  } = useRegistration();

  const steps = ['Enter Prolific ID', 'Connect Fitbit', 'Completion'];

  if (loading) {
    return (<CircularProgress />);
  }


  return (
    <Box>
      {!!error && <Typography color="error">{error}</Typography>}
      {!!success && <Typography color="success">{success}</Typography>}

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box>
          <TextField
            label="Prolific ID"
            value={pid || ''}
            onChange={(e) => setPid(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={storePid} disabled={!pid}>
            Next
          </Button>
        </Box>
      )}

      {(activeStep === 1 || activeStep === 2) && ( // TODO: Check steps again
        <Box>
          <Typography variant="h5" gutterBottom>
            Connect to Fitbit
          </Typography>
          <Button variant="contained" onClick={initiateFitbitAuth}>
            Connect to Fitbit
          </Button>
        </Box>
      )}

      {activeStep > 3 && (
        <Box>
          <Typography variant="h5" gutterBottom>
            You are all set!
          </Typography>
          <Typography variant="body1">Your registration is complete.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProlificRegistrationStepper;